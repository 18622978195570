import { createSlice } from "@reduxjs/toolkit";
let uData = localStorage.getItem("superadmin")
  ? localStorage.getItem("superadmin")
  : null;

const initialState = {
  userData: uData,
  isAuth: localStorage.getItem("LoggedIn") ? true : false,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // userLoggedIn: (state) => {
    //   state.isAuth = true;
    // },
    // userLogOut: (state) => ({
    //   userData: null,
    //   isAuth: false,
    // }),
    userLoggedIn: (state, { payload }) => {
      localStorage.setItem("superadmin", JSON.stringify(payload));
      console.log("jjjjjjj", payload);
      localStorage.setItem("LoggedIn", true);

      const d = new Date();
      let time = d.getTime();
      localStorage.setItem("TokenExpiry", time);

      return { ...state, isAuth: true, userData: payload };
    },
    userLogOut: (state, { payload }) => {
      localStorage.clear();
      return { ...state, userData: null, isAuth: false };
    },
    setUserData: (state, { payload }) => {
      return { ...state, setQus: 0, formData: "" };
    },
  },
});

// Action creators are generated for each case reducer function
export const { userLoggedIn, userLogOut, setUserData } = AuthSlice.actions;

export default AuthSlice.reducer;
