import React, { lazy, Suspense } from "react";
import {
  Route,
  Switch,
  useLocation,
  Navigate,
  useNavigate,
  useParams,
  BrowserRouter, Routes
} from "react-router-dom";
//import { Route, Switch, useLocation } from "react-router-dom";
import ErrorPage from "./Pages/PageNotFound/ErrorPage";
import Login from "./Pages/Auth/Login/Login";
import Forgot from "./Pages/Auth/Forgot/Forgot";

import Sidebar from "./Layouts/Sidebar";
import Header from "./Layouts/Header";
import ResetPassword from "./Pages/Auth/Forgot/ResetPassword";
//import { useUserContext } from "./components/Context/UserContext";

const MarketingSession = lazy(() =>
  import("./Pages/Marketing/MarketingSession")
);
const AddNewMarketingSession = lazy(() =>
  import("./Pages/Marketing/AddNewMarketingSession")
);
const MarketingVideoTherapy = lazy(() =>
  import("./Pages/Marketing/MarketingVideoTherapy")
);
const ViewMarketingSessionDetails = lazy(() =>
  import("./Pages/Marketing/ViewMarketingSessionDetails")
);
const MarketingWhiteBoard = lazy(() =>
  import("./Pages/Marketing/MarketingWhiteBoard")
);
const MarketingSessionFirstScreen = lazy(() =>
  import("./Pages/Marketing/MarketingSessionFirstScreen")
);

const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"));
const Notification = lazy(() => import("./Pages/Notification/Notification"));

const Tenant = lazy(() => import("./Pages/Tenant"));
const AddUpdateTenant = lazy(() => import("./Pages/Tenant/AddUpdateTenant"));
const TenantDetail = lazy(() =>
  import("./Pages/Tenant/TenantDetails/TenantDetail")
);
const AddEditIndividual = lazy(() =>
  import("./Pages/Tenant/AddEdit/AddEditIndividual")
);
const AddEditAdmin = lazy(() => import("./Pages/Tenant/AddEdit/AddEditAdmin"));
const AddEditRes = lazy(() => import("./Pages/Tenant/AddEdit/AddEditRes"));

const Forms = lazy(() => import("./components/Admin/Forms"));

const OtherUser = lazy(() => import("./Pages/OtherUser/OtherUser"));
const AddEditOtherUser = lazy(() =>
  import("./Pages/OtherUser/AddEdit/AddEditOtherUser")
);
const AddEditTenantUser = lazy(() =>
  import("./Pages/OtherUser/AddEdit/AddEditTenantUser")
);
const OtherUserDetail = lazy(() => import("./Pages/OtherUser/OtherUserDetail"));

const Settings = lazy(() => import("./Pages/Settings/Settings"));

const IcdTenCode = lazy(() =>
  import("./Pages/TherapistLicense/ICD-Code/IcdTenCode")
);
const AddEditIcdTenCode = lazy(() =>
  import("./Pages/TherapistLicense/ICD-Code/AddEditIcdTenCode")
);
const ViewICDCode = lazy(() =>
  import("./Pages/TherapistLicense/ICD-Code/ViewICD-Code")
);

const TherapyTypes = lazy(() =>
  import("./Pages/TherapistLicense/TherapyType/TherapyTypes")
);
const AddEditTherapyType = lazy(() =>
  import("./Pages/TherapistLicense/TherapyType/AddEditTherapyType")
);
const ViewTherapyType = lazy(() =>
  import("./Pages/TherapistLicense/TherapyType/ViewTherapyType")
);

const CptCode = lazy(() => import("./Pages/TherapistLicense/CPT-Code/CptCode"));
const AddEditCptCode = lazy(() =>
  import("./Pages/TherapistLicense/CPT-Code/AddEditCptCode")
);
const ViewCptCode = lazy(() =>
  import("./Pages/TherapistLicense/CPT-Code/ViewCPT-Code")
);

const BillingTabs = lazy(() => import("./Pages/BillingInfo/BillingTabs"));

const RepresentativeRoute = (authToken = "", user) => {
  console.log("user.userDetails.newUser", user);
  return authToken && user && !user.userDetails.newUser ? (
    <>
      <Suspense
        fallback={
          <div className="text-center">
            <span className="loader"></span>
          </div>
        }
      >
        <Routes>
          <Route
            
            path="/marketing-sessions"
            element={<MarketingSession/>}
          />
          <Route
            
            path="/marketing-sessions/?filter=id"
            element={<MarketingSession/>}
          />

          <Route
            
            path="/add-marketing-session/"
            element={<AddNewMarketingSession/>}
          />

          <Route
            
            path="/edit-marketing-session/:id"
            element={<AddNewMarketingSession/>}
          />

          <Route
            
            path="/marketing-video-therapy/:id"
            element={<MarketingVideoTherapy/>}
          />

          <Route
            
            path="/marketing-sessions-details/:id"
            element={<ViewMarketingSessionDetails/>}
          />

          <Route
            
            path="/marketing/whiteboard/:sessionid"
            element={<MarketingWhiteBoard/>}
          />

          <Route index path="/" element={<Dashboard/>} />
          <Route  path="/dashboard" element={<Dashboard/>} />
          <Route  path="/notifications" element={<Notification/>} />
          <Route  path="/tenant/" element={<Tenant/>} />
          <Route  path="/add-tenant/" element={<AddUpdateTenant/>} />
          <Route  path="/tenant-detail/:id" element={<TenantDetail/>} />
          <Route
            
            path="/edit-tenant-individual/:id"
            element={<AddEditIndividual/>}
          />
          <Route
            
            path="/add-tenant-admin/:tenantId"
            element={<AddEditAdmin/>}
          />
          <Route
            
            path="/edit-tenant-admin/:tenantId/:id"
            element={<AddEditAdmin/>}
          />
          <Route  path="/edit-tenant-res/:id" element={<AddEditRes/>} />
          {/* <Route
            
            path="/edit-tenant-admin/:adminId/:id"
            element={<AddEditAdmin}
          /> */}

          <Route
            
            path="/add-tenant-res/:tenantId"
            element={<AddEditRes/>}
          />
          <Route
            
            path="/edit-tenant-res/:tenantId/:id"
            element={<AddEditRes/>}
          />
          <Route  path="/other-users/" element={<OtherUser/>} />
          <Route  path="/add-other-user" element={<AddEditOtherUser/>} />
          <Route  path="/Add-Tenant-User" element={<AddEditTenantUser/>} />
          <Route
            
            path="/edit-other-user/:id"
            element={<AddEditOtherUser/>}
          />
          <Route  path="/other-user/:id" element={<OtherUserDetail/>} />
          <Route  path="/icd-10-codes/" element={<IcdTenCode/>} />
          <Route  path="/add-icd-10-code/" element={<AddEditIcdTenCode/>} />
          <Route
            
            path="/edit-icd-10-code/:id"
            element={<AddEditIcdTenCode/>}
          />
          <Route  path="/view-icd-10-code/:id" element={<ViewICDCode/>} />

          <Route  path="/cpt-codes/" element={<CptCode/>} />
          <Route  path="/add-cpt-code/" element={<AddEditCptCode/>} />
          <Route  path="/edit-cpt-code/:id" element={<AddEditCptCode/>} />
          <Route  path="/view-cpt-code/:id" element={<ViewCptCode/>} />

          <Route  path="/therapy-types/" element={<TherapyTypes/>} />
          <Route
            
            path="/add-therapy-type/"
            element={<AddEditTherapyType/>}
          />
          <Route
            
            path="/edit-therapy-type/:id"
            element={<AddEditTherapyType/>}
          />
          <Route
            
            path="/view-therapy-type/:id"
            element={<ViewTherapyType/>}
          />

          <Route  path="/billing" element={<BillingTabs/>} />
          <Route path="/settings" element={<Settings/>} />
          <Route  path="/forms" element={<Forms/>} />
          <Route path="" element={<ErrorPage/>} />
        </Routes>
      </Suspense>
    </>
  ) : (
    <>{AuthRoutes()}</>
  );
};

const AuthRoutes = () => {
  console.log("gooodddd");
  return (
    <Suspense
      fallback={
        <div className="text-center">
          <span className="loader"></span>
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<Login/>} />
        <Route path="/login" element={<Login/>} />
        <Route  path="/forgot-password" element={<Forgot/>} />
        <Route  path="/reset-password/" element={<ResetPassword/>} />
        <Route
          
          path="/reset-password/:token/:userUniqueId"
          element={<ResetPassword/>}
        />
        <Route
          
          path="/marketing-video-session/:id"
          element={<MarketingVideoTherapy/>}
        />
        <Route
            path="/marketing/whiteboard/:sessionid"
            element={<MarketingWhiteBoard/>}
          />
        <Route
          
          path="/misadmin/:email/sessionId/:id"
          element={<MarketingSessionFirstScreen/>}
        />
        <Route path="" element={<ErrorPage/>} />
      </Routes>
    </Suspense>
  );
};
function Router(props) {
  //const { user } = useUserContext();
  let location = useLocation();
  const user = localStorage.getItem("superadmin")
    ? JSON.parse(localStorage.getItem("superadmin"))
    : {};

  return (
    <>
      {props.Auth && user && !user.userDetails.newUser ? (
        <>
          <Header />
          <div className="d-flex flex-column flex-grow-1">
            <div className="container-fluid d-flex flex-column flex-grow-1">
              <div className="row flex-grow-1">
                {!location.pathname.includes("/marketing-video-therapy") && !location.pathname.includes("/marketing/whiteboard") &&
                  props.Auth && <Sidebar className="SideArea" />}
                {/* {props.Auth && <Sidebar className="SideArea" />} */}
                {location.pathname.includes("/marketing-video-therapy") || location.pathname.includes("/marketing/whiteboard") ? RepresentativeRoute(props.Auth, user)
                  :
                  <div className="col-md-10 no-padding ContentArea">
                    {RepresentativeRoute(props.Auth, user)}
                  </div>
                }
              </div>
            </div>
          </div>
        </>
      ) : (
        RepresentativeRoute(props.Auth, user)
      )}
      {/* {props.Auth && <Header />}
      <div className="d-flex flex-column flex-grow-1">
        <div className="container-fluid d-flex flex-column flex-grow-1">
          <div className="row flex-grow-1">
            {props.Auth && <Sidebar />}
            <div className="col-md-10 no-padding">
              {RepresentativeRoute(props.Auth)}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Router;
