import React, { useEffect } from "react";
import AppRoutes from "./Router";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { userLogOut } from "./Redux/Reducers/AuthSlice/AuthSlice";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const auth = useSelector((state) => state.auth.isAuth);
  let location = useLocation();

  //token Expiry logout
  const SessionTimeOut = localStorage.getItem("TokenExpiry");
  let history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const d = new Date();
    let C_time = d.getTime();
    //console.log("Expiry in:", C_time - (parseInt(SessionTimeOut) + 28800000));
    if (C_time > parseInt(SessionTimeOut) + 28800000) {
      dispatch(userLogOut());
      toast.error("Session expired, Please login again");
      history("/");
    }
  }, [location]);

  // token Expiry logout end

  return <AppRoutes Auth={auth} />;
}

export default App;
