import { createSlice } from "@reduxjs/toolkit";
// import { agoraTypes } from "../types";
const defaultState = {
  // loading effect
  loading: false,
  // media devices
  streams: [],
  localStream: null,
  currentStream: null,
  otherStreams: [],
  devicesList: [],
  // web sdk params
  config: {
    uid: 0,
    host: true,
    channelName: '',
    token: null,
    microphoneId: '',
    cameraId: '',
    resolution: '1080p',
    role: 'host'
  },
  agoraClient: null,
  mode: 'live',
  codec: 'h264',
  muteVideo: true,
  muteAudio: true,
  screen: false,
  profile: false
}
const initialState = {
  currentSession: null,
  users: [],
  screens: [],
  isRecording: false,
  controls: {
    screen: false,
    emoji: false,
  },
  volumeIndicators: [],
  configData: null,
};
const initialStateEmpty = {
  currentSession: null,
  users: [],
  screens: [],
  isRecording: false,
  controls: {
    screen: false,
    emoji: false,
  },
  volumeIndicators: [],
  configData: null,
};

const userJoined1 = (user, list) => {
  if (list.some((usr) => usr.agoraId === user?.agoraId)) {
    return list.map((currentUsr) => {
      if (currentUsr.agoraId === user.agoraId) {
        return {
          ...currentUsr,
          ...user,
        };
      }
      return currentUsr;
    });
  }
  return [...list, user];
};

const userLeave1 = (user, list) => {
  const uid =
    typeof user === "string" || typeof user === "number" ? user : user?.id;
  return list.filter((usr) => {
    if (usr.agoraId && user?.agoraId) {
      return usr.agoraId !== user?.agoraId;
    }
    return usr.id !== uid;
  });
};

const screenShare1 = (payload, screens) => {
  const { isEnabled, user } = payload;
  console.log("isEnabled....", payload);
  if (isEnabled) {
    const uid =
      typeof user === "string" || typeof user === "number" ? user : user?.id;
    // return [uid, ...(screens || [])];
    return [uid];
  } else {
    // return (screens || [])?.filter((id) => id !== uid);
    return [];
  }
};

const addAgoraVolumeIndicator = (indicators, initiallist) => {
  return [...initiallist, indicators];
};

export const AgoraSlice = createSlice({
  name: "agora",
  initialState,
  reducers: {
    userJoined: (state, { payload }) => {
      return {
        ...state,
        users: userJoined1(payload, state.users),
      };
    },
    userLeave: (state, { payload }) => {
      return {
        ...state,
        users: userLeave1(payload, state.users),
      };
    },
    screenShare: (state, { payload }) => {
      return {
        ...state,
        screens: screenShare1(payload, state.screens),
      };
    },
    agoraRecord: (state, { payload }) => {
      return {
        ...state,
        isRecording: payload,
      };
    },
    agoraControl: (state, { payload }) => {
      return {
        ...state,
        controls: { ...state.controls, ...payload },
      };
    },
    volumeIndicator: (state, { payload }) => {
      return {
        ...state,
        volumeIndicators: payload,
      };
    },
    configData: (state, { payload }) => {
      return {
        ...state,
        configData: payload,
      };
    },
    configUpdate: (state, { payload }) => {
      return initialStateEmpty;
    },
    clear: (state, { payload }) => {
      return initialStateEmpty;
    },
    setSession: (state, { payload }) => {
      return {
        ...state,
        currentSession: payload,
      };
    },
    clearAll: (state,{payload})=>{
      return {
        ...state,
        ...defaultState,
      }
    },
    config: (state,{payload})=>{
      return { ...state, config: payload }
    },
    client:(state,{payload})=>{
      return { ...state, client: payload }
    },
    loading:(state,{payload})=>{
      return { ...state, loading: payload }
    },
    codec:(state,{payload})=>{
      return { ...state, codec: payload }
    },
    video:(state,{payload})=>{
      return { ...state, muteVideo: payload }
    },
    audio:(state,{payload})=>{
      return { ...state, muteAudio: payload }
    },
    screen:(state,{payload})=>{
      return { ...state, screen: payload }
    },
    devicesList:(state,{payload})=>{
      return { ...state, devicesList: payload }
    },
    localStream:(state,{payload})=>{
      return { ...state, localStream: payload }
    },
    profile:(state,{payload})=>{
      return { ...state, profile: payload }
    },
    currentStream:(state,{payload})=>{
      const { streams } = state
      const newCurrentStream = payload
      const otherStreams = streams.filter(it => it.getId() !== newCurrentStream.getId())
      return { ...state, currentStream: newCurrentStream, otherStreams }
    },
    addStream:(state,{payload})=>{
      const { streams, currentStream } = state
      const newStream = payload
      let newCurrentStream = currentStream
      if (!newCurrentStream) {
        newCurrentStream = newStream
      }
      if (streams.length === 17) return { ...state }
      const newStreams = [...streams, newStream]
      const otherStreams = newStreams.filter(it => it.getId() !== newCurrentStream.getId())
      window.streams = newStreams
      return { ...state, streams: newStreams, currentStream: newCurrentStream, otherStreams }
    },
    removeStream:(state,{payload})=>{
      const { streams, currentStream } = state
      const { stream, uid } = payload
      const targetUid = stream ? stream.getId() : uid
      let newCurrentStream = currentStream
      const newStreams = streams
        .filter((stream) => (stream.getId() !== targetUid))
      if (targetUid === currentStream?.getId()) {
        if (newStreams.length === 0) {
          newCurrentStream = null
        } else {
          newCurrentStream = newStreams[0]
        }
      }
      const otherStreams = newCurrentStream ? newStreams.filter(it => it.getId() !== newCurrentStream.getId()) : []
      return { ...state, streams: newStreams, currentStream: newCurrentStream, otherStreams }
    },
    clearAllStream:(state)=>{
      const { streams, localStream, currentStream } = state
      streams.forEach((stream) => {
        if (stream.isPlaying()) {
          stream.stop()
        }
        stream.close()
      })

      if (localStream) {
        localStream.isPlaying() &&
        localStream.stop()
        localStream.close()
      }
      if (currentStream) {
        currentStream.isPlaying() &&
        currentStream.stop()
        currentStream.close()
      }
      return { ...state, currentStream: null, localStream: null, streams: [] }
    }
  },
});

export const {
  userJoined,
  userLeave,
  volumeIndicator,
  agoraControl,
  agoraRecord,
  screenShare,
  configData,
  configUpdate,
  setSession,
  clear,
  clearAll,
  config,
  client,
  loading,
  codec,
  video,
  audio,
  screen,
  devicesList,
  localStream,
  profile,
  currentStream,
  addStream,
  removeStream,
  clearAllStream,

} = AgoraSlice.actions;

export default AgoraSlice.reducer;
