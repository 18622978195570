import { apiUrl } from "./Config";
//import { toast } from "react-toastify";
import axios from "axios";

const header = () => {
  const user = localStorage.getItem("superadmin")
    ? JSON.parse(localStorage.getItem("superadmin"))
    : {};
  const user1 = localStorage.getItem("tempData")
    ? JSON.parse(localStorage.getItem("tempData"))
    : {};
  if (user && user.accessToken) {
    return {
      Authorization: "Bearer " + user?.accessToken,
      SessionId: user?.sessionId,
      "Content-Type": "application/json",
      Accept: "application/json",
      mode: "cors",
      "Access-Control-Allow-Origin": "*",
    };
  } else if (user1 && user1.userDetails?.userUniqueId) {
    return {
      Authorization: "Bearer " + user1?.accessToken,
      SessionId: user1?.sessionId,
      "Content-Type": "application/json",
      Accept: "application/json",
      mode: "cors",
      "Access-Control-Allow-Origin": "*",
    };
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      mode: "cors",
      "Access-Control-Allow-Origin": "*",
    };
  }
};

function logout() {
  //localStorage.removeItem("superadmin");
  //window.location.href = "/login";
  // patchMethod("auth/logout")
  //   .then((res) => {
  //     console.log("res");
  //     if (res.status == "Success") {
  //       localStorage.removeItem("superadmin");
  //       window.location.href = "/login";
  //     } else {
  //       toast.error(res.message);
  //     }
  //   })
  //   .catch((err) => toast.error(err));
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      // if any error like validation check or data not found, etc...

      if (response.status === 400) {
        return data;
      }
      if (response.status === 401 || response.status === 403) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      //return Promise.reject(error);
      return data;
    }
    if (data.code == 401) {
      logout();
    }
    console.log("farch E Code", data.code);

    return data;
  });
}

async function axiosGetMethod(endpoint) {
  //return await axios.get(basrUrl + "" + endpoint, { headers: header() });
  return await axios.get(`${apiUrl + "/" + endpoint}`, {
    headers: header(),
  });
}

async function axiosDeleteMethod(endpoint, Body) {
  //return await axios.get(basrUrl + "" + endpoint, { headers: header() });
  return await axios.delete(`${apiUrl + "/" + endpoint}`, {
    headers: header(),
    data:Body
  },
  );
}

async function axiosPostMethod(endpoint, Body) {
  //return await axios.get(basrUrl + "" + endpoint, { headers: header() });
  return await axios.post(`${apiUrl + "/" + endpoint}`, Body, {
    headers: header(),
  });
}

async function axiosPutMethod(endpoint, Body) {
  //return await axios.get(basrUrl + "" + endpoint, { headers: header() });
  return await axios.put(`${apiUrl + "/" + endpoint}`, Body, {
    headers: header(),
  });
}

async function postMethod(endpoint, dataobj) {
  // //return await axios.get(basrUrl + "" + endpoint, { headers: header() });
  // return await axios.post(`${apiUrl + "/" + endpoint}`, Body, {
  //   headers: header(),
  // });

  let options = {
    method: "POST",
    headers: header(),
    body: dataobj,
  };
  return await fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

async function putMethod(endpoint, dataobj) {
  let options = {
    method: "PUT",
    headers: header(),
    body: dataobj,
  };
  return await fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

async function patchMethod(endpoint) {
  let options = {
    method: "PATCH",
    headers: header(),
    // body: JSON.stringify({}),
  };
  return await fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

async function getMethod(endpoint, signal) {
  const requestOptions = {
    method: "GET",
    headers: header(),
    signal,
  };
  return await fetch(`${apiUrl}/${endpoint}`, requestOptions).then(
    handleResponse
  );
}

async function deleteMethod(endpoint, signal) {
  const requestOptions = {
    method: "DELETE",
    headers: header(),
    signal,
  };
  return await fetch(`${apiUrl}/${endpoint}`, requestOptions).then(
    handleResponse
  );
}

const Service = {
  axiosPostMethod,
  axiosGetMethod,
  axiosPutMethod,
  postMethod,
  putMethod,
  patchMethod,
  getMethod,
  deleteMethod,
  axiosDeleteMethod,
};

export default Service;
