import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Service from "../../../Service";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMsg, TokenError } from "../../../Config";
import TextInput from "../../../components/Admin/Inputs/TextInput";
import { userLoggedIn } from "../../../Redux/Reducers/AuthSlice/AuthSlice";

function ResetPassword() {
  let params = useParams();
  const {
    register,
    // formState: { errors },
    handleSubmit,
    watch,
  } = useForm();
  //   let user = useSelector((state) => state.adminUser.user);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [inputs, setInputs] = useState({
    password: "",
    confimPassword: "",
    tempToken: params?.token || "",
    userUniqueId: params?.userUniqueId || "",
  });
  const user = localStorage.getItem("tempData")
    ? JSON.parse(localStorage.getItem("tempData"))
    : {};

  const [loading, setLoading] = useState(false);
  const [errors, seterrors] = useState({
    password: "",
    confimPassword: "",
  });
  const onSubmit = (data) => {
    console.log("data",data);
    if (inputs.password !== inputs.confimPassword) {
      toast.warning("Password not matched");
    } else if (inputs.password && inputs.confimPassword) {
      const payload = {
        newPassword: inputs.password,
        tempToken: inputs?.tempToken || user?.accessToken,
        userUniqueId: inputs?.userUniqueId || user?.userDetails?.userUniqueId,
      };
      console.log("payload", payload?.tempToken,payload?.userUniqueId);
      if (payload?.tempToken && payload?.userUniqueId) {
        const url = params?.token
          ? `auth/reset-password`
          : `users/${user?.userDetails?.userUniqueId}`;
        if (params?.token) {
          Service.axiosPostMethod(url, payload).then(
            (res) => {
              if (res?.data?.status === "Success") {
                toast.success(res?.data?.message);
                setTimeout(() => {
                  history("/");
                }, 2000);
              } else {
                res?.data?.message && toast.error(res?.data?.message);
              }
            },
            (err) => {
              if (err?.response) {
                if (err.response.data.code === 404) {
                  toast.error(err?.response?.data?.message);
                }
              }
            }
          );
        } else {
          if (user?.userDetails?.userUniqueId) {
            Service.axiosPutMethod(url, { password: inputs.password }).then(
              (res) => {
                if (res?.data?.status === "Success") {
                  user.userDetails = res.data.data;

                  Service.axiosGetMethod(
                    `super-admin/user/${user.userDetails?.userUniqueId}`
                  )
                    .then((resData) => {
                      // setuserInfo(res?.data);
                      localStorage.setItem(
                        "userInfo",
                        JSON.stringify(resData.data?.data)
                      );
                      dispatch(userLoggedIn(user));
                      setTimeout(() => {
                        toast.success("Logged in successfully");
                        history("/dashboard");
                      }, 1000);
                    })
                    .catch((err) => {
                      // toast.error(err);
                    });
                } else {
                  res?.data?.message && toast.error(res?.data?.message);
                }
              },
              (err) => {
                if (err?.response) {
                  if (err.response.data.code === 404) {
                    toast.error(err?.response?.data?.message);
                  }
                }
              }
            );
          }
        }
      } else {
        toast.error("Tocken has been expire or onvalid");
      }
    } else {
      if (inputs.password == "" && inputs.confimPassword == "") {
        seterrors({
          password: "This field is required",
          confimPassword: "This field is required",
        });
      } else if (inputs.password == "") {
        seterrors({
          password: "This field is required",
          confimPassword: "",
        });
      } else if (inputs.confimPassword == "") {
        seterrors({
          password: "",
          confimPassword: "This field is required",
        });
      }
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  //   useEffect(() => {
  //     setInputs(inputs);
  //   }, [inputs]);

  return (
    <>
      <div className="col">
        <div className="container p-0">
          <div className="row align-items-center login-page">
            <div className="col-md-6 no-padding p-0">
              <div className="log-wrapper-left">
                <img
                  src="../../../resources/assets/Mask Group 2.png"
                  className="mask-login-left"
                />
                {
                  <img
                    src="../../../resources/assets/MIS-1.png"
                    className="login-logo"
                  />
                }
              </div>
              <img
                src="../../../resources/assets/left-login-img.jpg"
                className="left-img img-fluid"
              />
              <img
                src="../../../resources/assets/MIS-1.png"
                className="copyright-image "
              />
              <span className="copyright-text">Copyright @RCE 2021</span>
            </div>
            <div className="col-md-6 no-padding">
              <div className="row valign_wrapper padd-b-60-all ">
                <div className="custom-table-view-login">
                  <div className="col-lg-12 min-hei-250-all">
                    <div className="text-center login">
                      {window.location.host !== "cfdx.mis-live.com" ? (
                        <img className="mb-3 mb-sm-5 tenant-logo-img" alt="" />
                      ) : window.location.host === "acay.mis-live.com" ? (
                        <img
                          className="mb-3 mb-sm-5 tenant-logo-img"
                          src={
                            "https://meid-dev-public.s3.us-east-2.amazonaws.com/Achievement+Academy+Logo.png"
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          className="mb-3 mb-sm-5 tenant-logo-img"
                          src={
                            "https://meid-dev-public.s3.us-east-2.amazonaws.com/billinglogo.png"
                          }
                          alt=""
                        />
                      )}
                      <div className="form-signin-wrapper">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="custom-form form-custome-elements floting-form"
                        >
                          <h1 className="h3 mb-3 font-weight-normal text-left">
                            Reset Your Password
                          </h1>
                          <div className="form-group">
                            {/* <input
                              type="password"
                              // label="Password"
                              name="password"
                              handleChange={handleChange}
                              // {...register("password", {
                              //   required: true,
                              //   minLength: 8,
                              //   maxLength: 16,
                              // })}
                              defaultValue={inputs?.password}
                              placeholder="password"
                              className={`form-control ${
                                errors?.password && "is-invalid"
                              }`}
                            /> */}
                            <input
                              name="password"
                              onChange={handleChange}
                              defaultValue={inputs?.password}
                              type="password"
                              id="password"
                              // className={"form-control"}
                              required
                              className={`form-control ${
                                errors?.password && "is-invalid"
                              }`}
                            />
                            <label
                              className="form-control-placeholder"
                              for="inputEmail"
                            >
                              Enter New Password
                            </label>
                            {errors?.password &&
                              errors?.password.type === "required" && (
                                <div className="text-left invalid-feedback-custom">
                                  Field is required
                                </div>
                              )}
                            {errors?.password?.type === "minLength" && (
                              <div className="text-left invalid-feedback-custom">
                                Password must contain minimum 8 characters
                              </div>
                            )}
                            {errors?.password?.type === "maxLength" && (
                              <div className="text-left invalid-feedback-custom">
                                Password must contain maximum 16 characters
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            {/* <input
                              type="password"
                              label="Confim Password"
                              name="confimPassword"
                              handleChange={handleChange}
                              // {...register("confimPassword", {
                              //   required: true,
                              //   minLength: 8,
                              //   maxLength: 10,
                              // })}
                              defaultValue={inputs?.confimPassword}
                              placeholder="confimPassword"
                              className={`form-control ${
                                errors?.confimPassword && "is-invalid"
                              }`}
                            /> */}

                            <input
                              name="confimPassword"
                              onChange={handleChange}
                              defaultValue={inputs?.confimPassword}
                              type="password"
                              id="confimPassword"
                              // className={"form-control"}
                              required
                              className={`form-control ${
                                errors?.password && "is-invalid"
                              }`}
                            />
                            <label
                              className="form-control-placeholder"
                              for="confimPassword"
                            >
                              Confirm New Password
                            </label>
                            {errors?.confimPassword &&
                              errors?.confimPassword.type === "required" && (
                                <div className="text-left invalid-feedback-custom">
                                  Field is required
                                </div>
                              )}
                          </div>

                          <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                              <button
                                className="btn custom-btn-green"
                                type="submit"
                                disabled={loading}
                                block={true}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
