import { configureStore } from "@reduxjs/toolkit";
import agoraReducer from "../Redux/Reducers/AuthSlice/agoraReducer";
import whiteboardReducer from "./Reducers/AuthSlice/whiteboardReducer";
import authReducer from "../Redux/Reducers/AuthSlice/AuthSlice";
import loginReducer from "./Reducers/AuthSlice/LoginSlice";
//import { reducer as whiteBoardReduser } from "../components/agora/utils/store";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    login: loginReducer,
    agora: agoraReducer,
    wReducer:whiteboardReducer,
  },
});
