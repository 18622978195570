import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, generatePath } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { userLogOut } from "../../Redux/Reducers/AuthSlice/AuthSlice";
import billingIcon from "../../Assets/Images/invoice.png";
import "./sidebar.css";
import Collapse from "react-bootstrap/Collapse";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Sidebar = () => {
  let location = useLocation();
  let history = useNavigate();

  const [opendMenu, setopendMenu] = useState("");
  const [selectedMenu, setselectedMenu] = useState("");

  let d = new Date();
  const Year = d.getFullYear();

  const dispatch = useDispatch();

  useEffect(() => {
    const mnu = localStorage.getItem("opendMenu");
    if (mnu) setopendMenu(mnu);
  }, []);

  const changeMenu = (e) => {
    const nm = e.target.getAttribute("name");
    console.log("nm", nm);
    const val = opendMenu == nm ? "" : nm;
    setopendMenu(val);
    localStorage.setItem("opendMenu", nm);
  };

  const Logout = () => {
    //alert("ok");
    //localStorage.removeItem("superadmin");
    dispatch(userLogOut());
    localStorage.clear();
    setTimeout(() => {
      //toast.success("logout successfull");
      history("/login");
    }, 100);
    // Service.patchMethod("auth/logout")
    //   .then((res) => {
    //     console.log("res");
    //     if (res.status == "Success") {
    //       localStorage.removeItem("superadmin");
    //       window.location.href = "/login";
    //     } else {
    //       toast.error(res.message);
    //     }
    //   })
    //   .catch((err) => toast.error(err));
  };
  const Onboarding = [
    {
      link: "/tenant",
      imgSrc: "/resources/assets/Icon feather-monitor.svg",
      name: "Tenant",
      pathIncludes: [
        "/tenant",
        "/add-tenant",
        "/edit-tenant",
        "/edit-tenant-admin",
        "/edit-tenant-res",
        "/tenant-detail",
      ],
      class: "dropdown-active",
      isMenu: false,
      child: false,
    },
    {
      link: "/other-users",
      imgSrc: "/resources/assets/blank-user.svg",
      name: "Other User",
      pathIncludes: [
        "other-users",
        "add-other-user",
        "edit-other-user",
        "other-user-detail",
        "Add-Tenant-User",
      ],
      class: "dropdown-active",
      isMenu: false,
      child: false,
    },

    {
      link: "/marketing-sessions",
      name: "Marketing Appointment",
      imgSrc: "/resources/assets/file-earmark-text.svg",
      //   onClick: changeMenu,
      pathIncludes: [
        "/marketing-sessions",
        "/add-marketing-session",
        "/edit-marketing-session",
      ],
      class: "dropdown-active",
      isMenu: false,
      child: false,
    },
  ];
  const TherapyLicense = [
    {
      link: "/icd-10-codes",
      imgSrc: "/resources/assets/file-earmark-text.svg",
      name: "ICD 10 Codes",
      class: "dropdown-active",
      pathIncludes: ["icd-10-code", "add-icd-10-code", "edit-icd-10-code"],
      isMenu: false,
      child: false,
    },
    {
      link: "/cpt-codes",
      imgSrc: "/resources/assets/file-earmark-text.svg",
      name: "CPT Code",
      class: "dropdown-active",
      pathIncludes: ["cpt-codes", "add-cpt-code", "edit-cpt-code"],
      isMenu: false,
      child: false,
    },
    {
      link: "/therapy-types",
      imgSrc: "/resources/assets/file-earmark-text.svg",
      name: "Therapy Types",
      class: "dropdown-active",
      pathIncludes: ["therapy-type", "add-therapy-type", "edit-therapy-type"],
      isMenu: false,
      child: false,
    },
  ];
  const adminMenus = [
    {
      link: "/dashboard",
      imgSrc: "/resources/assets/dashboard.svg",
      name: "Dashboard",
      isMenu: true,
      child: false,
    },
    {
      link: "#",
      imgSrc: "/resources/assets/Icon feather-monitor.svg",
      name: "Onboarding",
      onClick: changeMenu,
      pathIncludes: [
        "/tenant",
        "/add-tenant",
        "/edit-tenant",
        "/edit-tenant-admin",
        "/edit-tenant-res",
        "tenant-detail",
        "other-users",
        "add-other-user",
        "edit-other-user",
      ],
      isMenu: true,
      child: true,
    },

    {
      link: "#",
      imgSrc: "/resources/assets/file-earmark-text.svg",
      name: "Therapist License",
      onClick: changeMenu,
      pathIncludes: [
        "icd-10-codes",
        "add-icd-10-code",
        "edit-icd-10-code",
        "cpt-codes",
        "add-cpt-code",
        "edit-cpt-code",
        "therapy-types",
        "add-therapy-type",
        "edit-therapy-type",
      ],
      isMenu: true,
      child: true,
    },
  ];

  let bottomCommonMenus = [
    {
      link: "/billing",
      imgSrc: "/resources/assets/invoice.png",
      name: "Billing Info",
      isMenu: true,
    },
    {
      link: "/settings",
      imgSrc: "/resources/assets/Icon feather-settings.svg",
      name: "Settings",
      isMenu: true,
    },
    {
      link: "#",
      onClick: Logout,
      imgSrc: "/resources/assets/Icon feather-log-out.svg",
      name: "Logout",
      isMenu: true,
    },
  ];

  const user = localStorage.getItem("superadmin")
    ? JSON.parse(localStorage.getItem("superadmin"))
    : {};
  const SidebarLink = ({
    link,
    isActive,
    imgSrc,
    name,
    onClick,
    isActiveMenu,
    isMenu,
    child,
    pathname,
  }) => {
    const list = opendMenu == "Onboarding" ? Onboarding : TherapyLicense;
    return (
      <>
        <li
          className={classNames({
            "sidebar-active": isActive,
            "selcted-active": opendMenu == name,
            "dropdown-active": isActiveMenu,
          })}
        >
          <Link
            to={link}
            onClick={onClick}
            name={name}
            style={{ display: "block" }}
          >
            {isMenu && (
              <img
                src={`${window.location.origin}${imgSrc}`}
                alt=""
                className="img-fluid"
                name={name}
              />
            )}{" "}
            <span name={name}>{name}</span>
            {child && (
              <span style={{ float: "right" }} onClick={onClick} name={name}>
                {opendMenu == name ? (
                  <IoIosArrowUp onClick={onClick} name={name} />
                ) : (
                  <IoIosArrowDown onClick={onClick} name={name} />
                )}
              </span>
            )}
          </Link>
        </li>
        {child && (
          <>
            {opendMenu === name && (
              <div class="content1">
                {list.map((o) => {
                  const isActiveItem = [o.link, o.pathIncludes]
                    .flat()
                    .some((item) => pathname.includes(item));
                  const isActiveMenuItem = o?.class || false;
                  return (
                    <li
                      className={classNames({
                        "sidebar-active": isActiveItem,
                        "dropdown-active": isActiveMenuItem,
                      })}
                    >
                      <Link to={o.link} name={o.name}>
                        {isMenu && (
                          <img
                            src={`${window.location.origin}${o.imgSrc}`}
                            alt=""
                            className="img-fluid"
                            name={o.name}
                          />
                        )}{" "}
                        <span name={o.name}>{o.name}</span>
                      </Link>
                    </li>
                  );
                })}
              </div>
            )}
            {/* <Collapse in={opendMenu}>
            {opendMenu === "Onboarding" ? (
              <div id="example-collapse-text">Anim</div>
            ) : (
              opendMenu === "Licence" && (
                <div id="example-collapse-text">Anim</div>
              )
            )}
          </Collapse> */}
          </>
        )}
      </>
    );
  };

  const Menus = ({ menus, pathname }) => (
    <ul className="sidebar-item">
      {menus.map((item, i) => {
        const isActive = [item.link, item.pathIncludes]
          .flat()
          .some((item) => pathname.includes(item));
        const isActiveMenu = item?.class || false;
        return (
          <SidebarLink
            key={item.link + i}
            {...item}
            isActive={isActive}
            isActiveMenu={isActiveMenu}
            isMenu={item.isMenu}
            child={item.child}
            pathname={pathname}
          />
        );
      })}
    </ul>
  );

  return (
    <>
      <div className="c-sidebar col-md-2 no-padding scrollMenu">
        {/* {user && !user.userDetails.newUser && (
          <>
            <Menus menus={topMenus} pathname={location.pathname} />
            <hr />
            <Menus menus={bottomCommonMenus} pathname={location.pathname} />
          </>
        )} */}

        {user && !user.userDetails.newUser && (
          <>
            <Menus menus={adminMenus} pathname={location.pathname} />
            <hr />
            <Menus menus={bottomCommonMenus} pathname={location.pathname} />
          </>
        )}
        <div className="copyright">
          <img
            src="../resources/assets/MIS-1.png"
            className="copyright-image-sidebar "
            alt="mis"
          />
          <span className="copyright-text-sidebar">Copyright @RCE {Year}</span>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
