import { createSlice } from "@reduxjs/toolkit";
// import { agoraTypes } from "../types";
const initialState = {
  // loading effect
  loading: false,
  // media devices
  streams: [],
  localStream: null,
  currentStream: null,
  otherStreams: [],
  devicesList: [],
  // web sdk params
  config: {
    uid: 0,
    host: true,
    channelName: '',
    token: null,
    microphoneId: '',
    cameraId: '',
    resolution: '1080p',
    role: 'host'
  },
  agoraClient: null,
  mode: 'live',
  codec: 'h264',
  muteVideo: true,
  muteAudio: true,
  screen: false,
  profile: false
}

export const WhiteBoardSlice = createSlice({
  name: "whiteBoardSlice",
  initialState,
  reducers: {
    clearAll: (state,{payload})=>{
      return {
        ...state,
        ...initialState,
      }
    },
    config: (state,{payload})=>{
      return { ...state, config: payload }
    },
    client:(state,{payload})=>{
      return { ...state, client: payload }
    },
    loading:(state,{payload})=>{
      return { ...state, loading: payload }
    },
    codec:(state,{payload})=>{
      return { ...state, codec: payload }
    },
    video:(state,{payload})=>{
      return { ...state, muteVideo: payload }
    },
    audio:(state,{payload})=>{
      return { ...state, muteAudio: payload }
    },
    screen:(state,{payload})=>{
      return { ...state, screen: payload }
    },
    devicesList:(state,{payload})=>{
      return { ...state, devicesList: payload }
    },
    localStream:(state,{payload})=>{
      return { ...state, localStream: payload }
    },
    profile:(state,{payload})=>{
      return { ...state, profile: payload }
    },
    currentStream:(state,{payload})=>{
      const { streams } = state
      const newCurrentStream = payload
      const otherStreams = streams.filter(it => it.getId() !== newCurrentStream.getId())
      return { ...state, currentStream: newCurrentStream, otherStreams }
    },
    addStream:(state,{payload})=>{
      const { streams, currentStream } = state
      const newStream = payload
      let newCurrentStream = currentStream
      if (!newCurrentStream) {
        newCurrentStream = newStream
      }
      if (streams.length === 17) return { ...state }
      const newStreams = [...streams, newStream]
      const otherStreams = newStreams.filter(it => it.getId() !== newCurrentStream.getId())
      window.streams = newStreams
      return { ...state, streams: newStreams, currentStream: newCurrentStream, otherStreams }
    },
    removeStream:(state,{payload})=>{
      const { streams, currentStream } = state
      const { stream, uid } = payload
      const targetUid = stream ? stream.getId() : uid
      let newCurrentStream = currentStream
      const newStreams = streams
        .filter((stream) => (stream.getId() !== targetUid))
      if (targetUid === currentStream?.getId()) {
        if (newStreams.length === 0) {
          newCurrentStream = null
        } else {
          newCurrentStream = newStreams[0]
        }
      }
      const otherStreams = newCurrentStream ? newStreams.filter(it => it.getId() !== newCurrentStream.getId()) : []
      return { ...state, streams: newStreams, currentStream: newCurrentStream, otherStreams }
    },
    clearAllStream:(state)=>{
      const { streams, localStream, currentStream } = state
      streams.forEach((stream) => {
        if (stream.isPlaying()) {
          stream.stop()
        }
        stream.close()
      })

      if (localStream) {
        localStream.isPlaying() &&
        localStream.stop()
        localStream.close()
      }
      if (currentStream) {
        currentStream.isPlaying() &&
        currentStream.stop()
        currentStream.close()
      }
      return { ...state, currentStream: null, localStream: null, streams: [] }
    }
  },
});

export const {
  clearAll,
  config,
  client,
  loading,
  codec,
  video,
  audio,
  screen,
  devicesList,
  localStream,
  profile,
  currentStream,
  addStream,
  removeStream,
  clearAllStream,

} = WhiteBoardSlice.actions;

export default WhiteBoardSlice.reducer;
