import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  ghh: 9,
};

export const counterSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loggedIn: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the State because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      alert("ok");
      state.value += 1;
    },
    logOut: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { loggedIn, logOut, incrementByAmount } = counterSlice.actions;

export default counterSlice.reducer;
