import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./Redux/Store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { UserProvider } from "./components/Context/UserContext";
import "./index.scss";

export const appReduxStore = store;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
      <UserProvider>
        <Router>
          <App />
        </Router>
      </UserProvider>
    </Provider>
 //</React.StrictMode>,
  //document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
