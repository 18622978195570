import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Bell from "../../Assets/Images/bell.png";
import "./Header.scss";
import Service from "../../Service";
import { toast } from "react-toastify";

const Header = () => {
  const history = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);
  const user = localStorage.getItem("superadmin")
    ? JSON.parse(localStorage.getItem("superadmin"))
    : {};

  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  useEffect(() => {
    afterSeen();
  }, []);

  const afterSeen = (isRedirect = "") => {
    let timer = setInterval(() => {
      Service.axiosGetMethod(
        `notifications/get-new-notification-count/${userInfo.userUniqueId}`
      ).then(
        (res) => {
          if (res?.data.status === "Success") {
            setNotificationCount(res.data.data);
          } else {
            res?.data.message
              ? toast.error(res?.data.message)
              : console.log("Somthing went wrong");
          }
          isRedirect && history("/notifications");
        },
        (error) => {
          console.log(error);
        }
      );
    }, 50000);
    clearInterval(() => {
      return timer;
    });
  };

  const seenNotification = () => {
    let api = `notifications/update-notification-seen-value/${userInfo.userUniqueId}`;
    Service.axiosPutMethod(api).then(
      (res) => {
        if (res.status === "Success") {
          afterSeen("redirect");
        } else {
          toast.error(res.message);
          history("/notifications");
        }
      },
      (error) => {
        console.log(error);
        history("/notifications");
      }
    );
  };

  return (
    <>
      {user && !user.userDetails.newUser && (
        <header className="header">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-auto">
                <div onClick={() => history(`/dashboard`)}>
                  <img
                    className="tenant-logo-img-header"
                    src="../resources/assets/MIS-1.png"
                    alt=""
                    width="35%"
                  />
                </div>
              </div>
              <div className="col-auto">
                <div className="d-flex">
                  <div className="user-info">
                    <span className="admin-name">
                      <img
                        src={`${
                          userInfo?.profilePicUrl ||
                          "/resources/assets/defaultprofile.png"
                        }`}
                        className="image"
                        alt="User profile img"
                      />
                      <div className="content ml-2">
                        <sapn className="text-capitalize">MIS Admin</sapn>
                        <large className="name">
                          <br />
                          {userInfo?.firstName} {userInfo?.lastName}
                        </large>
                        <small>
                          <br />
                          {userInfo?.email || user?.userDetails?.email}
                        </small>
                      </div>
                    </span>
                  </div>

                  <div className="notificationBox">
                    <Link
                    // onClick={() => console.log("first")}
                    >
                      <img
                        src={"/resources/assets/bell.svg"}
                        alt="Notification"
                        className="bellImage"
                        onClick={seenNotification}
                      />
                    </Link>
                    <span id="group">
                      {notificationCount === 0 ? (
                        <></>
                      ) : (
                        <small className="not-Count">{notificationCount}</small>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
