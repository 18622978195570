import React from "react";
import { BsInfoCircle } from "react-icons/bs";

function TextInput(props) {
  console.log("error msg", props?.err?.ref?.name)
  return (
    <>
      <div className="form-group mb-15">
        {props?.label && <label>{props.label}&nbsp;</label>}
        {props?.isRequire && <span className="required-span">*</span>}
        <input
          type={props?.type ? props?.type : "text"}
          name={props?.name}
          {...props?.require}
          // required={props?.isRequire}
          className={`form-control ${props.err && "is-invalid"} ${props?.advError && "is-invalid"
            }`}
          onChange={(e) => props.handleChange(e)}
          defaultValue={props?.value}
          // value={props?.value}
          placeholder={`Enter ${props?.placeholder}`}
          {...props?.step}
          disabled={props?.disabled || false}
        />
        {props?.example && (
          <p style={{ color: "grey", fontSize: 11.3 }}>
            <BsInfoCircle />
            &nbsp;{props?.example}
          </p>
        )}
        {props?.advError && (
          <span className="fieldError">{props.advError}</span>
        )}
        {props?.err?.ref?.name === 'maxFee'
          ?
            <span className="fieldError">
              Please enter decimal value.
            </span>
          : props?.err ?
          <span className="fieldError">
            {props?.advError || "This field is required."}
          </span>
          : 
          ''
        }
      </div>
    </>
  );
}

export default TextInput;
